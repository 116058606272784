import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Translations } from './i18n/types';

// Import translations as objects instead of JSON files
const enTranslation: Translations = {
  tagline: "Building Agricultural Bridges",
  nav: {
    services: "Services",
    team: "Team",
    contact: "Contact",
    request: "Request Equipment"
  },
  hero: {
    title: "Quality Farm Equipment Across the World",
    subtitle: "Connecting farmers with affordable, high-quality agricultural equipment",
    cta: "Request Equipment"
  },
  request: {
    title: "Request Equipment",
    subtitle: "Tell us about your equipment needs",
    equipmentType: "Equipment Type",
    selectType: "Select equipment type",
    balerLargeSquare: "Large Square Baler",
    balerSmallSquare2: "Small Square Baler (2-String)",
    balerSmallSquare3: "Small Square Baler (3-String)",
    dewpoint: "DewPoint System",
    planter: "Planter",
    rake: "Rake",
    tractor: "Tractor",
    other: "Other Equipment",
    otherPlaceholder: "Please specify the equipment you're looking for",
    minBudget: "Minimum Budget (USD)",
    maxBudget: "Maximum Budget (USD)",
    targetDate: "Target Delivery Date",
    details: "Additional Details",
    detailsPlaceholder: "Tell us more about your requirements",
    name: "Your Name",
    namePlaceholder: "Enter your full name",
    whatsapp: "WhatsApp Number",
    whatsappPlaceholder: "Enter your WhatsApp number",
    email: "Email Address",
    emailPlaceholder: "Enter your email address",
    phonePlaceholder: "Phone Number (optional)",
    locationPlaceholder: "Delivery Location",
    location: "Delivery Location",
    submit: "Submit Request",
    submitting: "Submitting...",
    successMessage: "Thank you! We'll be in touch soon.",
    errorMessage: "Something went wrong. Please try again."
  },
  services: {
    title: "Our Services",
    subtitle: "We provide comprehensive solutions for agricultural equipment needs",
    shipping: "International Shipping",
    shippingDesc: "Efficient and reliable shipping to your chosen international port, from US to the world",
    import: "Import Assistance",
    importDesc: "Expert guidance through the import process and documentation",
    equipment: "Equipment Sourcing",
    equipmentDesc: "Access to quality agricultural equipment at competitive prices",
    refurbishment: "Equipment Refurbishment",
    refurbishmentDesc: "Professional restoration and upgrading of equipment to meet our rigorous quality standards",
    implementation: "In Country Implementation",
    implementationDesc: "Local support for equipment setup and integration into your operations",
    support: "Technical Support",
    supportDesc: "Expert equipment maintenance and operational support for your machinery"
  },
  team: {
    title: "Our Team",
    subtitle: "Meet the people making cross-border agriculture possible",
    russell: "Russell Mitchell",
    blake: "Blake Houskeeper",
    founderRole: "Founder",
    cofounderRole: "Co-Founder",
    partnerRole: "Partner"
  },
  contact: {
    title: "Contact Us",
    submit: "Send Message",
    submitting: "Sending...",
    successMessage: "Thank you for your message! We'll be in touch soon.",
    errorMessage: "Failed to send message. Please try again.",
    namePlaceholder: "Your Name",
    emailPlaceholder: "Your Email",
    messagePlaceholder: "Your Message"
  },
  footer: {
    description: "Building bridges in agricultural equipment trade between the US and the world",
    contact: "Contact Info",
    locations: "Locations",
    social: "Follow Us",
    rights: "All rights reserved.",
    copyright: " 2024 AgriPuente. All rights reserved."
  }
};

const esTranslation: Translations = {
  tagline: "Construyendo Puentes Agrícolas",
  nav: {
    services: "Servicios",
    team: "Equipo",
    contact: "Contacto",
    request: "Solicitar Equipo"
  },
  hero: {
    title: "Equipos Agrícolas de Calidad en Todo el Mundo",
    subtitle: "Conectando agricultores con equipos agrícolas asequibles y de alta calidad",
    cta: "Solicitar Equipo"
  },
  request: {
    title: "Solicitar Equipo",
    subtitle: "Cuéntanos sobre tus necesidades de equipo",
    equipmentType: "Tipo de Equipo",
    selectType: "Selecciona el tipo de equipo",
    balerLargeSquare: "Empacadora Cuadrada Grande",
    balerSmallSquare2: "Empacadora Cuadrada Pequeña (2 Cuerdas)",
    balerSmallSquare3: "Empacadora Cuadrada Pequeña (3 Cuerdas)",
    dewpoint: "Sistema Dewpoint",
    planter: "Sembradora",
    rake: "Rastrillo",
    tractor: "Tractor",
    other: "Otro Equipo",
    otherPlaceholder: "Por favor, especifica el equipo que buscas",
    minBudget: "Presupuesto Mínimo (USD)",
    maxBudget: "Presupuesto Máximo (USD)",
    targetDate: "Fecha de Entrega Objetivo",
    details: "Detalles Adicionales",
    detailsPlaceholder: "Cuéntanos más sobre tus requisitos",
    name: "Tu Nombre",
    namePlaceholder: "Ingresa tu nombre completo",
    whatsapp: "Número de WhatsApp",
    whatsappPlaceholder: "Ingresa tu número de WhatsApp",
    email: "Correo Electrónico",
    emailPlaceholder: "Ingresa tu correo electrónico",
    phonePlaceholder: "Número de Teléfono (opcional)",
    locationPlaceholder: "Ubicación de Entrega",
    location: "Ubicación de Entrega",
    submit: "Enviar Solicitud",
    submitting: "Enviando...",
    successMessage: "¡Gracias! Nos pondremos en contacto contigo pronto.",
    errorMessage: "Algo salió mal. Por favor, inténtalo de nuevo."
  },
  services: {
    title: "Nuestros Servicios",
    subtitle: "Proporcionamos soluciones integrales para necesidades de equipos agrícolas",
    shipping: "Envío Internacional",
    shippingDesc: "Servicios de envío eficientes y confiables al puerto internacional de tu elección, de EE.UU. al mundo",
    import: "Asistencia en Importación",
    importDesc: "Guía experta a través del proceso de importación y documentación",
    equipment: "Abastecimiento de Equipos",
    equipmentDesc: "Acceso a equipos agrícolas de calidad a precios competitivos",
    refurbishment: "Restauración de Equipos",
    refurbishmentDesc: "Restauración y actualización profesional de equipos para cumplir con nuestros rigurosos estándares de calidad",
    implementation: "Implementación Local",
    implementationDesc: "Soporte local para la configuración e integración del equipo en sus operaciones",
    support: "Soporte Técnico",
    supportDesc: "Soporte experto en mantenimiento y operación de su maquinaria"
  },
  team: {
    title: "Nuestro Equipo",
    subtitle: "Conoce a las personas que hacen posible la agricultura transfronteriza",
    russell: "Russell Mitchell",
    blake: "Blake Houskeeper",
    founderRole: "Fundador",
    cofounderRole: "Co-Fundador",
    partnerRole: "Socio"
  },
  contact: {
    title: "Contáctanos",
    submit: "Enviar Mensaje",
    submitting: "Enviando...",
    successMessage: "¡Gracias por tu mensaje! Nos pondremos en contacto pronto.",
    errorMessage: "Error al enviar el mensaje. Por favor, inténtalo de nuevo.",
    namePlaceholder: "Tu Nombre",
    emailPlaceholder: "Tu Correo Electrónico",
    messagePlaceholder: "Tu Mensaje"
  },
  footer: {
    description: "Construyendo puentes en el comercio de equipos agrícolas entre EE.UU. y el mundo",
    contact: "Información de Contacto",
    locations: "Ubicaciones",
    social: "Síguenos",
    rights: "Todos los derechos reservados.",
    copyright: " 2024 AgriPuente. Todos los derechos reservados."
  }
};

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'translation';
    resources: {
      translation: Translations;
    };
  }
}

const resources = {
  en: {
    translation: enTranslation
  },
  es: {
    translation: esTranslation
  }
};

// Detect user language from browser
const detectUserLanguage = (): string => {
  // List of languages our app supports
  const supportedLanguages = ['en', 'es'];
  
  // Get browser language (primary or full language code like 'en-US')
  const browserLanguage = navigator.language || (navigator as any).userLanguage || '';
  
  // Extract base language code (e.g., 'en' from 'en-US')
  const baseLanguage = browserLanguage.split('-')[0].toLowerCase();
  
  // Check if the detected language is supported, otherwise use default
  return supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: detectUserLanguage(),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
