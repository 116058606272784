import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation, Trans } from 'react-i18next';
import {
  DocumentCheckIcon,
  UserGroupIcon,
  GlobeAmericasIcon,
  SparklesIcon,
  MapPinIcon,
  WrenchScrewdriverIcon,
  GlobeAltIcon,
  SunIcon,
  MoonIcon,
  EnvelopeIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import {
  GiCargoShip
} from 'react-icons/gi';
import {
  FaInstagram,
  FaYoutube,
  FaFacebook
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import logo from './assets/images/AgriPuente Logo.png';
import './App.css';

// Get the full URL for the logo in production
const getLogoUrl = () => {
  try {
    return new URL('../src/assets/images/AgriPuente Logo.png', import.meta.url).href;
  } catch (e) {
    console.warn('Fallback to direct import for logo:', e);
    return logo;
  }
};

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [requestForm, setRequestForm] = useState({
    equipmentType: '',
    otherEquipment: '',
    minBudget: '',
    maxBudget: '',
    targetDate: '',
    name: '',
    email: '',
    whatsapp: '',
    details: '',
    location: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isContactSubmitting, setIsContactSubmitting] = useState(false);
  const [contactError, setContactError] = useState<string | null>(null);
  const [contactSuccess, setContactSuccess] = useState(false);

  useEffect(() => {
    const logoUrl = getLogoUrl();
    console.log('Setting watermark with URL:', logoUrl);
    document.documentElement.style.setProperty('--watermark-image', `url("${logoUrl}")`);
  }, []);

  const services = [
    {
      icon: <GlobeAmericasIcon className="h-12 w-12 text-accent" />,
      titleKey: 'services.equipment',
      descriptionKey: 'services.equipmentDesc'
    },
    {
      icon: <SparklesIcon className="h-12 w-12 text-accent" />,
      titleKey: 'services.refurbishment',
      descriptionKey: 'services.refurbishmentDesc'
    },
    {
      icon: <GiCargoShip className="h-12 w-12 text-accent" />,
      titleKey: 'services.shipping',
      descriptionKey: 'services.shippingDesc'
    },
    {
      icon: <DocumentCheckIcon className="h-12 w-12 text-accent" />,
      titleKey: 'services.import',
      descriptionKey: 'services.importDesc'
    },
    {
      icon: <MapPinIcon className="h-12 w-12 text-accent" />,
      titleKey: 'services.implementation',
      descriptionKey: 'services.implementationDesc'
    },
    {
      icon: <WrenchScrewdriverIcon className="h-12 w-12 text-accent" />,
      titleKey: 'services.support',
      descriptionKey: 'services.supportDesc'
    }
  ];

  const team = [
    {
      name: 'russell',
      role: 'cofounderRole',
      country: '🇺🇸'
    },
    {
      name: 'blake',
      role: 'cofounderRole',
      country: '🇺🇸'
    }
  ];

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      // Add a small delay to allow the mobile menu to close
      setTimeout(() => {
        const navHeight = 80; // Approximate nav height
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - navHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  const handleRequestSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);
    
    try {
      const response = await fetch('/api/request-equipment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestForm)
      });

      const data = await response.json() as { success?: boolean; error?: string; details?: string; type?: string };
      console.log('Response data:', data);
      
      if (!response.ok) {
        throw new Error(data.details || data.error || 'Failed to submit form');
      }

      setSubmitSuccess(true);
      setSubmitError(null);
      setRequestForm({
        equipmentType: '',
        otherEquipment: '',
        minBudget: '',
        maxBudget: '',
        targetDate: '',
        name: '',
        email: '',
        whatsapp: '',
        details: '',
        location: ''
      });
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitError(
        error instanceof Error 
          ? error.message 
          : 'Failed to submit form. Please try again.'
      );
      setSubmitSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleContactSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsContactSubmitting(true);
    setContactError(null);
    setContactSuccess(false);

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactForm)
      });

      const data = await response.json() as { success?: boolean; error?: string; details?: string };
      
      if (!response.ok) {
        throw new Error(data.details || data.error || t('contact.errorMessage'));
      }

      setContactSuccess(true);
      setContactForm({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Contact form submission error:', error);
      setContactError(
        error instanceof Error 
          ? error.message 
          : String(error)
      );
    } finally {
      setIsContactSubmitting(false);
    }
  };

  return (
    <Router>
      <div className="relative min-h-screen bg-primary-light dark:bg-primary-dark text-gray-900 dark:text-white transition-colors duration-300">
        <nav className="fixed w-full z-50 bg-white/80 dark:bg-black/80 backdrop-blur-lg">
          <div className="container mx-auto px-6 py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <img 
                    src={getLogoUrl()} 
                    alt="AgriPuente" 
                    className="h-12 w-auto transition-transform dark:invert cursor-pointer" 
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  />
                </motion.div>
                <div className="hidden md:block">
                  <h1 className="text-xl font-semibold bg-gradient-to-r from-accent to-emerald-600 bg-clip-text text-transparent">
                    <Trans>tagline</Trans>
                  </h1>
                </div>
              </div>
              
              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center space-x-6">
                <a 
                  href="#services" 
                  onClick={(e) => { e.preventDefault(); scrollToSection('services'); }}
                  className="text-gray-800 dark:text-white hover:text-accent transition-colors"
                >
                  <Trans>nav.services</Trans>
                </a>
                <a 
                  href="#team" 
                  onClick={(e) => { e.preventDefault(); scrollToSection('team'); }}
                  className="text-gray-800 dark:text-white hover:text-accent transition-colors"
                >
                  <Trans>nav.team</Trans>
                </a>
                <a 
                  href="#request" 
                  onClick={(e) => { e.preventDefault(); scrollToSection('request'); }}
                  className="text-gray-800 dark:text-white hover:text-accent transition-colors"
                >
                  <Trans>nav.request</Trans>
                </a>
                <a 
                  href="#contact" 
                  onClick={(e) => { e.preventDefault(); scrollToSection('contact'); }}
                  className="text-gray-800 dark:text-white hover:text-accent transition-colors"
                >
                  <Trans>nav.contact</Trans>
                </a>
              </div>

              {/* Theme and Language Controls (Both Mobile and Desktop) */}
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => document.documentElement.classList.toggle('dark')}
                  className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
                  aria-label="Toggle theme"
                >
                  {document.documentElement.classList.contains('dark') ? (
                    <SunIcon className="h-5 w-5" />
                  ) : (
                    <MoonIcon className="h-5 w-5" />
                  )}
                </button>
                <button
                  onClick={() => {
                    i18n.changeLanguage(i18n.language === 'en' ? 'es' : 'en');
                  }}
                  className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-accent dark:hover:text-accent transition-colors"
                  aria-label="Toggle language"
                >
                  <GlobeAltIcon className="w-5 h-5" />
                  <span>{i18n.language === 'en' ? 'EN' : 'ES'}</span>
                </button>

                {/* Mobile Menu Button */}
                <button
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="md:hidden p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
                  aria-label="Toggle mobile menu"
                >
                  {isMobileMenuOpen ? (
                    <XMarkIcon className="h-6 w-6" />
                  ) : (
                    <Bars3Icon className="h-6 w-6" />
                  )}
                </button>
              </div>
            </div>

            {/* Mobile Navigation Menu */}
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: isMobileMenuOpen ? 1 : 0,
                height: isMobileMenuOpen ? 'auto' : 0
              }}
              transition={{ duration: 0.3 }}
              className="md:hidden overflow-hidden"
            >
              <div className="py-4 space-y-4">
                <button 
                  onClick={() => {
                    scrollToSection('services');
                    setIsMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                >
                  <Trans>nav.services</Trans>
                </button>
                <button 
                  onClick={() => {
                    scrollToSection('team');
                    setIsMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                >
                  <Trans>nav.team</Trans>
                </button>
                <button 
                  onClick={() => {
                    scrollToSection('request');
                    setIsMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                >
                  <Trans>nav.request</Trans>
                </button>
                <button 
                  onClick={() => {
                    scrollToSection('contact');
                    setIsMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                >
                  <Trans>nav.contact</Trans>
                </button>
              </div>
            </motion.div>
          </div>
        </nav>

        <main>
          {/* Hero Section */}
          <section className="min-h-screen flex items-center justify-center pt-20 bg-gradient-to-b from-primary-light to-secondary-light dark:from-primary-dark dark:to-secondary-dark relative overflow-hidden">
            <div className="container mx-auto px-6 relative z-10">
              <motion.div
                initial="initial"
                animate="animate"
                variants={{
                  animate: {
                    transition: {
                      staggerChildren: 0.2
                    }
                  }
                }}
                className="text-center relative z-10"
              >
                <motion.div variants={{
                  initial: { 
                    opacity: 0, 
                    y: 60 
                  },
                  animate: { 
                    opacity: 1, 
                    y: 0,
                    transition: {
                      duration: 0.8,
                      ease: "easeOut"
                    }
                  }
                }}>
                  <div className="text-center space-y-8">
                    <motion.h1
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.8, ease: "easeOut" }}
                      className="text-4xl md:text-6xl font-bold"
                    >
                      <Trans>hero.title</Trans>
                    </motion.h1>
                    <motion.p
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
                      className="text-xl md:text-2xl text-gray-600 dark:text-gray-300"
                    >
                      <Trans>hero.subtitle</Trans>
                    </motion.p>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
                    >
                      <button
                        onClick={() => {
                          const requestSection = document.getElementById('request');
                          if (requestSection) {
                            const start = window.pageYOffset;
                            const end = requestSection.offsetTop;
                            const duration = 1500; // 1.5 seconds
                            const startTime = performance.now();
                            
                            const easeInOutCubic = (t: number): number => 
                              t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;

                            const animate = (currentTime: number): void => {
                              const elapsed = currentTime - startTime;
                              const progress = Math.min(elapsed / duration, 1);

                              window.scrollTo(0, start + (end - start) * easeInOutCubic(progress));

                              if (progress < 1) {
                                requestAnimationFrame(animate);
                              }
                            };

                            requestAnimationFrame(animate);
                          }
                        }}
                        className="bg-accent hover:bg-accent/90 text-white px-8 py-3 rounded-lg text-lg font-medium transition-colors"
                      >
                        <Trans>hero.cta</Trans>
                      </button>
                    </motion.div>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </section>

          {/* Services Section */}
          <section id="services" className="py-20 relative overflow-hidden bg-secondary-light dark:bg-secondary-dark">
            <div className="container mx-auto px-6 relative z-10">
              <motion.div
                initial="initial"
                whileInView="animate"
                viewport={{ once: true, margin: "-100px" }}
                variants={{
                  animate: {
                    transition: {
                      staggerChildren: 0.2
                    }
                  }
                }}
                className="text-center mb-16"
              >
                <motion.h2 variants={{
                  initial: { 
                    opacity: 0, 
                    y: 60 
                  },
                  animate: { 
                    opacity: 1, 
                    y: 0,
                    transition: {
                      duration: 0.8,
                      ease: "easeOut"
                    }
                  }
                }} className="text-4xl font-bold">
                  <Trans>services.title</Trans>
                </motion.h2>
                <motion.p variants={{
                  initial: { 
                    opacity: 0, 
                    y: 60 
                  },
                  animate: { 
                    opacity: 1, 
                    y: 0,
                    transition: {
                      duration: 0.8,
                      ease: "easeOut"
                    }
                  }
                }} className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
                  <Trans>services.subtitle</Trans>
                </motion.p>
              </motion.div>

              <motion.div
                initial="initial"
                whileInView="animate"
                viewport={{ once: true, margin: "-100px" }}
                variants={{
                  animate: {
                    transition: {
                      staggerChildren: 0.2
                    }
                  }
                }}
                className="grid grid-cols-1 md:grid-cols-3 gap-8"
              >
                {services.map((service, index) => (
                  <motion.div
                    key={index}
                    variants={{
                      initial: { 
                        opacity: 0, 
                        scale: 0.9
                      },
                      animate: { 
                        opacity: 1, 
                        scale: 1,
                        transition: {
                          duration: 0.5,
                          ease: "easeOut"
                        }
                      }
                    }}
                    whileHover={{ y: -10, transition: { duration: 0.3 } }}
                    className="bg-white/50 dark:bg-black/50 backdrop-blur-sm p-8 rounded-xl hover:bg-white/60 dark:hover:bg-black/60 transition-colors"
                  >
                    <motion.div 
                      className="flex justify-center mb-6 text-accent"
                      whileHover={{ rotate: 360, transition: { duration: 0.6 } }}
                    >
                      {service.icon}
                    </motion.div>
                    <h3 className="text-xl font-semibold mb-4">
                      <Trans>{service.titleKey}</Trans>
                    </h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      <Trans>{service.descriptionKey}</Trans>
                    </p>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </section>

          {/* Team Section */}
          <section id="team" className="py-20 bg-primary-light dark:bg-primary-dark relative overflow-hidden">
            <div className="container mx-auto px-6 relative z-10">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "-100px" }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="text-center mb-16"
              >
                <h2 className="text-4xl font-bold">
                  <Trans>team.title</Trans>
                </h2>
                <p className="text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
                  <Trans>team.subtitle</Trans>
                </p>
              </motion.div>

              <motion.div
                initial="initial"
                whileInView="animate"
                viewport={{ once: true, margin: "-100px" }}
                variants={{
                  animate: {
                    transition: {
                      staggerChildren: 0.08,
                      delayChildren: 0.1
                    }
                  }
                }}
                className="grid grid-cols-1 md:grid-cols-2 gap-8 perspective-1000 mx-auto justify-center max-w-4xl"
              >
                {team.map((member, index) => (
                  <motion.div
                    key={index}
                    variants={{
                      initial: { 
                        x: -30,
                        rotateY: -10,
                        scale: 0.95,
                      },
                      animate: { 
                        x: 0,
                        rotateY: 0,
                        scale: 1,
                        transition: {
                          type: "spring",
                          stiffness: 70,
                          damping: 20,
                          mass: 1.2
                        }
                      }
                    }}
                    className="bg-white/50 dark:bg-black/50 backdrop-blur-sm p-6 rounded-xl text-center transform transition-all duration-300 hover:-translate-y-2 hover:bg-white/60 dark:hover:bg-black/60"
                    style={{ transformStyle: "preserve-3d" }}
                  >
                    <div 
                      className="w-24 h-24 bg-accent/20 rounded-full mx-auto mb-4 flex items-center justify-center transition-all duration-300 hover:rotate-12 hover:scale-105"
                    >
                      <UserGroupIcon className="h-12 w-12 text-accent" />
                    </div>
                    <h3 className="text-xl font-semibold mb-2">
                      <Trans>{`team.${member.name}`}</Trans> {member.country}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      <Trans>{`team.${member.role}`}</Trans>
                    </p>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </section>

          {/* Equipment Request Form Section */}
          <section id="request" className="py-20 bg-primary-light dark:bg-primary-dark relative overflow-hidden">
            <div className="container mx-auto px-6 relative z-10">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
                className="max-w-3xl mx-auto"
              >
                <h2 className="text-4xl font-bold text-center mb-8">
                  <Trans>request.title</Trans>
                </h2>
                <p className="text-gray-600 dark:text-gray-400 text-center mb-12">
                  <Trans>request.subtitle</Trans>
                </p>

                <form onSubmit={handleRequestSubmit} className="space-y-8">
                  {/* Equipment Type */}
                  <div className="space-y-4">
                    <label className="block text-lg font-medium">
                      <Trans>request.equipmentType</Trans>
                    </label>
                    <select 
                      className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                      value={requestForm.equipmentType}
                      onChange={(e) => {
                        setRequestForm(prev => ({ ...prev, equipmentType: e.target.value }));
                        const otherInput = document.getElementById('otherEquipment');
                        if (otherInput) {
                          otherInput.style.display = e.target.value === 'other' ? 'block' : 'none';
                        }
                      }}
                      required
                    >
                      <option value=""><Trans>request.selectType</Trans></option>
                      {(() => {
                        type EquipmentKey = 
                          | 'balerLargeSquare'
                          | 'balerSmallSquare2'
                          | 'balerSmallSquare3'
                          | 'dewpoint'
                          | 'planter'
                          | 'rake'
                          | 'tractor'
                          | 'other';

                        const equipmentKeys: EquipmentKey[] = [
                          'balerLargeSquare',
                          'balerSmallSquare2',
                          'balerSmallSquare3',
                          'dewpoint',
                          'planter',
                          'rake',
                          'tractor',
                          'other'
                        ];

                        return [...equipmentKeys]
                          .sort((a, b) => {
                            if (b === 'other') return -1;
                            
                            // Sort the rest alphabetically
                            const textA = t(`request.${a}`) as string;
                            const textB = t(`request.${b}`) as string;
                            return textA.localeCompare(textB, 'en');
                          })
                          .map((key) => (
                            <option key={key} value={key}>
                              <Trans>{`request.${key}`}</Trans>
                            </option>
                          ));
                      })()}
                    </select>
                  </div>

                  {/* Other Equipment Input */}
                  <div id="otherEquipment" className="space-y-4 hidden">
                    <input
                      type="text"
                      className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                      placeholder={t('request.otherPlaceholder')}
                      value={requestForm.otherEquipment}
                      onChange={(e) => setRequestForm(prev => ({ ...prev, otherEquipment: e.target.value }))}
                    />
                  </div>

                  {/* Budget Range */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <label className="block text-lg font-medium">
                        <Trans>request.minBudget</Trans>
                      </label>
                      <div className="relative">
                        <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">$</span>
                        <input
                          type="text"
                          className="w-full pl-8 pr-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                          value={requestForm.minBudget}
                          onChange={(e) => setRequestForm(prev => ({ ...prev, minBudget: e.target.value }))}
                        />
                      </div>
                    </div>
                    <div className="space-y-4">
                      <label className="block text-lg font-medium">
                        <Trans>request.maxBudget</Trans>
                      </label>
                      <div className="relative">
                        <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">$</span>
                        <input
                          type="text"
                          className="w-full pl-8 pr-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                          value={requestForm.maxBudget}
                          onChange={(e) => setRequestForm(prev => ({ ...prev, maxBudget: e.target.value }))}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Target Date */}
                  <div className="space-y-4">
                    <label className="block text-lg font-medium">
                      <Trans>request.targetDate</Trans>
                    </label>
                    <input
                      type="date"
                      className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                      value={requestForm.targetDate}
                      onChange={(e) => setRequestForm(prev => ({ ...prev, targetDate: e.target.value }))}
                    />
                  </div>

                  {/* Additional Details */}
                  <div className="space-y-4">
                    <label className="block text-lg font-medium">
                      <Trans>request.details</Trans>
                    </label>
                    <textarea
                      rows={4}
                      className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors resize-none"
                      placeholder={t('request.detailsPlaceholder')}
                      value={requestForm.details}
                      onChange={(e) => setRequestForm(prev => ({ ...prev, details: e.target.value }))}
                      required
                    ></textarea>
                  </div>

                  {/* Contact Information */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <label className="block text-lg font-medium">
                        <Trans>request.name</Trans>
                      </label>
                      <input
                        type="text"
                        className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                        value={requestForm.name}
                        onChange={(e) => setRequestForm(prev => ({ ...prev, name: e.target.value }))}
                        required
                      />
                    </div>
                    <div className="space-y-4">
                      <label className="block text-lg font-medium">
                        <Trans>request.whatsapp</Trans>
                      </label>
                      <input
                        type="tel"
                        className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                        value={requestForm.whatsapp}
                        onChange={(e) => setRequestForm(prev => ({ ...prev, whatsapp: e.target.value }))}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-6">
                    <div className="space-y-4">
                      <label className="block text-lg font-medium">
                        <Trans>request.email</Trans>
                      </label>
                      <input
                        type="email"
                        className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                        value={requestForm.email}
                        onChange={(e) => setRequestForm(prev => ({ ...prev, email: e.target.value }))}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <label className="block text-lg font-medium">
                        <Trans>request.location</Trans>
                      </label>
                      <input
                        type="text"
                        className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                        value={requestForm.location}
                        onChange={(e) => setRequestForm(prev => ({ ...prev, location: e.target.value }))}
                        required
                      />
                    </div>
                  </div>

                  {submitError && (
                    <div className="text-red-500 text-center">
                      {submitError}
                    </div>
                  )}

                  {submitSuccess && (
                    <div className="text-green-500 text-center">
                      <Trans>request.successMessage</Trans>
                    </div>
                  )}

                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-accent text-white px-8 py-3 rounded-full text-lg font-medium hover:bg-opacity-90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isSubmitting ? (
                        <Trans>request.submitting</Trans>
                      ) : (
                        <Trans>request.submit</Trans>
                      )}
                    </button>
                  </div>
                </form>
              </motion.div>
            </div>
          </section>

          {/* Contact Section */}
          <section id="contact" className="py-20 bg-black/30 relative overflow-hidden">
            <div className="container mx-auto px-6 relative z-10">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
                className="max-w-3xl mx-auto"
              >
                <h2 className="text-4xl font-bold text-center mb-8">
                  <Trans>contact.title</Trans>
                </h2>

                <form onSubmit={handleContactSubmit} className="space-y-8">
                  {/* Name */}
                  <div className="space-y-4">
                    <input
                      type="text"
                      className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                      placeholder={t('contact.namePlaceholder')}
                      value={contactForm.name}
                      onChange={(e) => setContactForm(prev => ({ ...prev, name: e.target.value }))}
                      required
                    />
                  </div>

                  {/* Email */}
                  <div className="space-y-4">
                    <input
                      type="email"
                      className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors"
                      placeholder={t('contact.emailPlaceholder')}
                      value={contactForm.email}
                      onChange={(e) => setContactForm(prev => ({ ...prev, email: e.target.value }))}
                      required
                    />
                  </div>

                  {/* Message */}
                  <div className="space-y-4">
                    <textarea
                      rows={6}
                      className="w-full px-4 py-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 focus:ring-2 focus:ring-accent focus:border-transparent transition-colors resize-none"
                      placeholder={t('contact.messagePlaceholder')}
                      value={contactForm.message}
                      onChange={(e) => setContactForm(prev => ({ ...prev, message: e.target.value }))}
                      required
                    ></textarea>
                  </div>

                  {contactError && (
                    <div className="text-red-500 text-center">
                      {contactError}
                    </div>
                  )}

                  {contactSuccess && (
                    <div className="text-green-500 text-center">
                      <Trans>contact.successMessage</Trans>
                    </div>
                  )}

                  <div className="text-center">
                    <motion.button
                      type="submit"
                      disabled={isContactSubmitting}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-accent text-white px-8 py-3 rounded-full text-lg font-medium hover:bg-opacity-90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isContactSubmitting ? (
                        <Trans>contact.submitting</Trans>
                      ) : (
                        <Trans>contact.submit</Trans>
                      )}
                    </motion.button>
                  </div>
                </form>
              </motion.div>
            </div>
          </section>

          {/* Footer */}
          <footer className="bg-secondary-light dark:bg-secondary-dark py-16">
            <div className="container mx-auto px-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                {/* Company Info */}
                <div className="space-y-4">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="inline-block"
                  >
                    <img 
                      src={getLogoUrl()} 
                      alt="AgriPuente" 
                      className="h-16 w-auto dark:invert" 
                    />
                  </motion.div>
                  <p className="text-gray-600 dark:text-gray-400 text-sm leading-relaxed">
                    <Trans>footer.description</Trans>
                  </p>
                </div>

                {/* Contact Info */}
                <div className="space-y-4">
                  <h3 className="font-bold text-lg mb-4">
                    <Trans>footer.contact</Trans>
                  </h3>
                  <div className="space-y-3">
                    <div className="flex items-center space-x-3 text-gray-600 dark:text-gray-400">
                      <EnvelopeIcon className="h-5 w-5" />
                      <a href="mailto:info@agripuente.com" className="hover:text-accent transition-colors">
                        info@agripuente.com
                      </a>
                    </div>
                  </div>
                </div>

                {/* Social Links */}
                <div className="space-y-4">
                  <h3 className="font-bold text-lg mb-4">
                    <Trans>footer.social</Trans>
                  </h3>
                  <div className="flex space-x-6">
                    <motion.a
                      href="https://twitter.com/agripuente"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-accent transition-colors"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FaXTwitter className="h-6 w-6" />
                    </motion.a>
                    <motion.a
                      href="https://instagram.com/agripuente"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-600 dark:text-gray-400 hover:text-accent transition-colors"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FaInstagram className="h-6 w-6" />
                    </motion.a>
                    <motion.a
                      href="https://youtube.com/agripuente"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-600 dark:text-gray-400 hover:text-accent transition-colors"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FaYoutube className="h-6 w-6" />
                    </motion.a>
                    <motion.a
                      href="https://facebook.com/agripuente"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-600 dark:text-gray-400 hover:text-accent transition-colors"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FaFacebook className="h-6 w-6" />
                    </motion.a>
                  </div>
                </div>
              </div>

              {/* Copyright */}
              <div className="border-t border-gray-200 dark:border-gray-800 mt-12 pt-8 text-center text-gray-600 dark:text-gray-400">
                <p className="text-sm">
                  &copy; {new Date().getFullYear()} AgriPuente. <Trans>footer.rights</Trans>
                </p>
              </div>
            </div>
          </footer>
        </main>
      </div>
    </Router>
  );
}

export default App;
